import React, { Component } from 'react';
import { AppExtensionSDK } from '@contentful/app-sdk';
import {
  Heading,
  Form,
  Workbench,
  CheckboxField,
  HelpText,
  TextField,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  IconButton,
  TextInput,
  Tooltip,
  Subheading,
} from '@contentful/forma-36-react-components';
import { css } from 'emotion';

interface TranslationNamespaceMapItemType {
  title: string,
  titleError: boolean,
  namespace: string,
  namespaceError: boolean,
  description: string,
}

interface TranslationNamespaceMapItemEditableType extends TranslationNamespaceMapItemType {
  editable: boolean,
}

export interface AppInstallationParameters {
  deliveryAccessToken: string,
  previewAccessToken: string,
  managementAccessToken: string,
  translationServiceUrl: string,
  enableTestingMode: boolean,
  translationNamespaceMap: Array<TranslationNamespaceMapItemType>,
}

type Parameters = keyof AppInstallationParameters

interface ConfigProps {
  sdk: AppExtensionSDK;
}

interface ConfigState {
  parameters: AppInstallationParameters;
  // stores the state of currently-saved dropdown items
  translationNamespaceMap: Array<TranslationNamespaceMapItemEditableType>,
  // stores the state of a new dropdown item
  translationNamespaceMapItem: TranslationNamespaceMapItemType,
}

export default class Config extends Component<ConfigProps, ConfigState> {
  constructor(props: ConfigProps) {
    super(props);
    this.state = {
      parameters: {
        deliveryAccessToken: '',
        previewAccessToken: '',
        managementAccessToken: '',
        translationServiceUrl: '',
        enableTestingMode: false,
        translationNamespaceMap: [],
      },
      translationNamespaceMap: [],
      translationNamespaceMapItem: {
        title: '',
        titleError: false,
        namespace: '',
        namespaceError: false,
        description: '',
      },
    };

    // `onConfigure` allows to configure a callback to be
    // invoked when a user attempts to install the app or update
    // its configuration.
    props.sdk.app.onConfigure(() => this.onConfigure());
  }

  async componentDidMount() {
    // Get current parameters of the app.
    // If the app is not installed yet, `parameters` will be `null`.
    const parameters: AppInstallationParameters | null = await this.props.sdk.app.getParameters();
    const translationNamespaceMap: Array<TranslationNamespaceMapItemEditableType> = parameters?.translationNamespaceMap?.map((e: TranslationNamespaceMapItemType) => ({...e, editable: false})) ?? [];
    const translationNamespaceMapItem = {
      title: '',
      titleError: false,
      namespace: '',
      namespaceError: false,
      description: '',
    };

    this.setState(parameters ? { parameters, translationNamespaceMap, translationNamespaceMapItem } : this.state, () => {
      // Once preparation has finished, call `setReady` to hide
      // the loading screen and present the app to a user.
      this.props.sdk.app.setReady();
    });
  }

  onConfigure = async () => {
    // This method will be called when a user clicks on "Install"
    // or "Save" in the configuration screen.
    // for more details see https://www.contentful.com/developers/docs/extensibility/ui-extensions/sdk-reference/#register-an-app-configuration-hook

    // Get current the state of EditorInterface and other entities
    // related to this app installation
    const currentState = await this.props.sdk.app.getCurrentState();

    return {
      // Parameters to be persisted as the app configuration.
      parameters: this.state.parameters,
      // In case you don't want to submit any update to app
      // locations, you can just pass the currentState as is
      targetState: currentState,
    };
  };

  handleInput = (tokenType: string) => (e: any) => {
    const accessToken = e.target.value;
    this.setState({
      parameters: {
        ...this.state.parameters,
        [tokenType]: accessToken,
      },
    });
  };

  trimFields = (element: TranslationNamespaceMapItemEditableType | TranslationNamespaceMapItemType): TranslationNamespaceMapItemEditableType | TranslationNamespaceMapItemType => ({
    ...element,
    title: element.title.trim(),
    namespace: element.namespace.trim(),
    description: element.description.trim(),
  });

  handleCheckboxInput = (checkboxType: Parameters) => () => {
    this.setState({
      parameters: {
        ...this.state.parameters,
        [checkboxType]: !this.state.parameters[checkboxType],
      },
    });
  };

  handleDropdownItemInput = (field: string, index?: number) => (e: any) => {
    const userInput = e.target.value;

    if (index === undefined) {
      // when editing a new row
      this.setState({
        translationNamespaceMapItem: {
          ...this.state.translationNamespaceMapItem,
          [field]: userInput,
        },
      });
    } else {
      const updated = this.state.translationNamespaceMap.map((element, i) => {
        if (index === i) {
          return {
            ...element,
            [field]: userInput,
          };
        }

        return element;
      });

      this.setState({
        translationNamespaceMap: updated,
      });
    }
  };

  // Called when + button is clicked to add a new dropdown item
  handleAddDropdownItem = () => {
    const item = this.state.translationNamespaceMapItem;

    const { title, namespace } = item;

    // minimal validation
    if (!title || !namespace) {
      this.setState({
        translationNamespaceMapItem: {
          ...this.state.translationNamespaceMapItem,
          titleError: !title,
          namespaceError: !namespace,
        }
      })
      return;
    }

    // add the new dropdown item to parameters
    const map = (this.state.parameters.translationNamespaceMap ?? []).concat(item);
    // trim the input
    const updatedMap = map.map(this.trimFields);
    const updatedMapWithEditableField = updatedMap.map((e: TranslationNamespaceMapItemType) => ({...e, editable: false}));
    this.setState({
      parameters: {
        ...this.state.parameters,
        translationNamespaceMap: updatedMap,
      },
      translationNamespaceMap: updatedMapWithEditableField,
      // reset input
      translationNamespaceMapItem: {
        title: '',
        titleError: false,
        namespace: '',
        namespaceError: false,
        description: '',
      },
    })
  }

  // Called when X is clicked on a dropdown item
  handleRemoveDropdownItem = ({ namespace }: any) => () => {
    // find the element with namespace from the map and remove the element
    const foundIndex = this.state.parameters.translationNamespaceMap.findIndex((element) => element.namespace === namespace);
    const mapCopy = JSON.parse(JSON.stringify(this.state.parameters.translationNamespaceMap));
    const updatedMap = mapCopy.slice(0, foundIndex).concat(mapCopy.slice(foundIndex + 1));

    this.setState({
      parameters: {
        ...this.state.parameters,
        translationNamespaceMap: updatedMap,
      },
      translationNamespaceMap: updatedMap.map((e: TranslationNamespaceMapItemType)  => ({...e, editable: false})),
    });
  }

  // save is true when a user is done editing
  toggleEditMode = (index: number, save: boolean) => () => {
    const updated = this.state.translationNamespaceMap.map(this.trimFields).map((element: any, i) => ({
      ...element,
      editable: index === i ? !element.editable : false,
    }));

    if (!save) {
      this.setState({
        translationNamespaceMap: updated,
      });
      return;
    }

    // done editing. save.
    const { title, namespace } = this.state.translationNamespaceMap.find((_, i) => i === index) as TranslationNamespaceMapItemEditableType;
    if (!title || !namespace) {
      this.setState({
        translationNamespaceMap: this.state.translationNamespaceMap.map((element, i) => ({
          ...element,
          titleError: index === i ? !title : false,
          namespaceError: index === i ? !namespace : false,
        })),
      });
      return;
    }

    this.setState({
      parameters: {
        ...this.state.parameters,
        ...(save ? {
          translationNamespaceMap: updated.map((element) => ({
            title: element.title,
            titleError: false,
            namespace: element.namespace,
            namespaceError: false,
            description: element.description,
          })),
        } : {}),
      },
      translationNamespaceMap: updated,
    });
  }

  render() {
    return (
      <Workbench className={css({ margin: '80px' })}>
        <Form className={css({ width: '800px' })}>
          <Heading>Translator Config</Heading>
          <TextField
            id="deliveryAccessToken"
            name="deliveryAccessToken"
            required
            labelText="Delivery Access Token"
            value={this.state.parameters.deliveryAccessToken}
            onChange={this.handleInput('deliveryAccessToken')}
          />
          <TextField
            id="previewAccessToken"
            name="previewAccessToken"
            required
            labelText="Preview Access Token"
            value={this.state.parameters.previewAccessToken}
            onChange={this.handleInput('previewAccessToken')}
          />
          <TextField
            id="managementAccessToken"
            name="managementAccessToken"
            required
            labelText="Management Access Token"
            value={this.state.parameters.managementAccessToken}
            onChange={this.handleInput('managementAccessToken')}
          />
          <TextField
            id="translationServiceUrl"
            name="translationServiceUrl"
            required
            labelText="Translation Service URL"
            value={this.state.parameters.translationServiceUrl}
            onChange={this.handleInput('translationServiceUrl')}
          />
          <Subheading>Translation Namespace Table</Subheading>
          <Table className={css({ margin: '0.5em' })}>
            <TableHead>
              <TableRow>
                <TableCell>Dropdown option</TableCell>
                <TableCell>Translation namespace</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Add/Edit</TableCell>
                <TableCell>Remove</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                this.state.translationNamespaceMap?.map(({ title, titleError, namespace, namespaceError, description, editable}, index) => {
                  return (
                    <TableRow key={`input_${index}`}>
                      <TableCell>
                        { editable ? (
                          <TextInput
                            required
                            name={`translationNamespaceMapTitle_${index}`}
                            id={`translationNamespaceMapTitle_${index}`}
                            value={title}
                            error={titleError}
                            onChange={this.handleDropdownItemInput('title', index)}
                          />
                        ) : title}
                      </TableCell>
                      <TableCell>
                        { editable ? (
                          <TextInput
                            required
                            name={`translationNamespaceMapNamespace_${index}`}
                            id={`translationNamespaceMapNamespace_${index}`}
                            value={namespace}
                            error={namespaceError}
                            onChange={this.handleDropdownItemInput('namespace', index)}
                          />
                        ) : namespace}
                      </TableCell>
                      <TableCell>
                        { editable ? (
                          <TextInput
                            name={`translationNamespaceMapDescription_${index}`}
                            id={`translationNamespaceMapDescription_${index}`}
                            value={description}
                            onChange={this.handleDropdownItemInput('description', index)}
                          />
                        ) : description}
                      </TableCell>
                      <TableCell>
                        {
                          !editable? (
                            <Tooltip
                              place="top"
                              id={`${namespace}_${index}`}
                              targetWrapperClassName="targetWrapperClassName"
                              content="Enable edit mode"
                            >
                              <IconButton
                                iconProps={{ icon: 'EditTrimmed' }}
                                onClick={this.toggleEditMode(index, false)}
                              />
                            </Tooltip>
                          ): (
                            <IconButton
                              iconProps={{ icon: 'CheckCircleTrimmed' }}
                              onClick={this.toggleEditMode(index, true)}
                            />
                          )
                        }
                      </TableCell>
                      <TableCell>
                        <Tooltip
                          place="top"
                          id={`${namespace}_${index}`}
                          targetWrapperClassName="targetWrapperClassName"
                          content="Click to remove this dropdow item"
                        >
                          <IconButton
                            iconProps={{ icon: 'CloseTrimmed' }}
                            label={`Add me to your sidebar`}
                            onClick={this.handleRemoveDropdownItem({ namespace })}
                          />
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  )
                })
              }
              <TableRow>
                <TableCell>
                  <TextInput
                    required
                    name="translationNamespaceMapTitle"
                    id="translationNamespaceMapTitle"
                    placeholder="dropdown copy"
                    error={this.state.translationNamespaceMapItem.titleError}
                    value={this.state.translationNamespaceMapItem.title}
                    onChange={this.handleDropdownItemInput('title')}
                  />
                </TableCell>
                <TableCell>
                  <TextInput
                    required
                    name="translationNamespaceMapNamespace"
                    id="translationNamespaceMapNamespace"
                    placeholder="namespace"
                    error={this.state.translationNamespaceMapItem.namespaceError}
                    value={this.state.translationNamespaceMapItem.namespace}
                    onChange={this.handleDropdownItemInput('namespace')}
                  />
                </TableCell>
                <TableCell>
                  <TextInput
                    name="translationNamespaceMapDescription"
                    id="translationNamespaceMapDescription"
                    placeholder="description"
                    value={this.state.translationNamespaceMapItem.description}
                    onChange={this.handleDropdownItemInput('description')}
                  />
                </TableCell>
                <TableCell>
                  <Tooltip
                    place="top"
                    id="tip2"
                    targetWrapperClassName="targetWrapperClassName"
                    content="Click to add a new dropdow item"
                  >
                    <IconButton
                      iconProps={{ icon: 'Plus' }}
                      label={`Add me to your sidebar`}
                      onClick={this.handleAddDropdownItem}
                    />
                  </Tooltip>
                </TableCell>
                <TableCell />
              </TableRow>
            </TableBody>
          </Table>
          <HelpText>When retrieving translations, the app will fetch from the namespaces specified in the dropdown order above when there are multiple namespaces associated with an entry.</HelpText>
          <CheckboxField
            id="enableTestingMode"
            name="enableTestingMode"
            labelText="Enable Testing Mode"
            helpText="In testing mode, submitting/authoring translations will be ignored."
            checked={this.state.parameters.enableTestingMode}
            onChange={this.handleCheckboxInput('enableTestingMode')}
          />
        </Form>
      </Workbench>
    );
  }
}

import React, { useState, useEffect } from 'react';
import base32 from 'base32';
import {
  Accordion,
  AccordionItem,
  Button,
  CheckboxField,
  CopyButton,
  Paragraph,
} from '@contentful/forma-36-react-components';

import { TranslationResultTable } from '../TranslationResultTable/TranslationResultTable';
import { TRANSLATION_KEY_SPLITTER } from '../../utils/constants';

import './TranslationList.css';

export const TranslationList = ({
  sdk,
  translations,
  localizedFields,
  updateSelectedKeys,
} : {
  sdk: any,
  translations: any,
  localizedFields: any,
  updateSelectedKeys: any,
}) => {
  // select all checkbox is checked by default
  const [selectAllKeys, setSelectAllKeys] = useState(true);
  const [selectedKeys, setSelectedKeys] = useState<any>({});
  const [initialized, setInitialized] = useState(false);

  const translationKeys = Object.keys(translations?.data?.translations ?? []);
  const translationsList = translations?.data?.translations ?? {};

  useEffect(() => {
    // all keys are selected by default
    const initialSelectedKeys = translationKeys.reduce((acc: any, key: string) => {
      return {
        ...acc,
        [key]: true,
      };
    }, {});

    setSelectedKeys(initialSelectedKeys);
    updateSelectedKeys(initialSelectedKeys);
    setInitialized(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialized]);

  const selectAllCheckboxHandler = () => {
    const updatedSelectedKeys = Object.keys(selectedKeys).reduce((acc, key) => {
      return {
        ...acc,
        [key]: !selectAllKeys,
      };
    }, {});
    setSelectedKeys(updatedSelectedKeys);
    updateSelectedKeys(updatedSelectedKeys);

    setSelectAllKeys(!selectAllKeys);
  };

  const individualCheckboxHandler = (key: string) => {
    const currentState = selectedKeys[key];

    // individual checkbox update
    const updatedSelectedKeys = Object.keys(selectedKeys).reduce((acc, translationKey) => {
      if (translationKey !== key) {
        return acc;
      }

      return {
        ...acc,
        [translationKey]: !currentState,
      };
    }, selectedKeys);

    setSelectedKeys(updatedSelectedKeys);
    updateSelectedKeys(updatedSelectedKeys);

    // selectAll checkbox update
    if (Object.keys(updatedSelectedKeys).every(key => updatedSelectedKeys[key])) {
      setSelectAllKeys(true);
    } else {
      setSelectAllKeys(false);
    }
  }

  return (
    <div className="translation-list-wrapper">
      <Paragraph className='translation-stats'>
        {`Number of completed keys / Total number of keys : ${translations?.data?.status?.translated} / ${translations?.data?.status?.total}`}
      </Paragraph>
      <CheckboxField
        className="select-all"
        name="select-all"
        id="select-all"
        labelText="Select all"
        onChange={selectAllCheckboxHandler}
        checked={selectAllKeys}
      />
      {
        Object.keys(translationsList).map((translationKey: string) => {
          const [entryId, , fieldName] = base32.decode(translationKey).split(TRANSLATION_KEY_SPLITTER);
          const total = translationsList?.[translationKey]?.status?.total;
          const translated = translationsList?.[translationKey]?.status?.translated;

          return (
            <Accordion key={translationKey}>
              <AccordionItem
                title={(
                  <div className="tl-accordion-item-wrapper">
                    <div
                      className="tl-item-checkbox-div"
                      onClick={(e) => {
                        e.stopPropagation();  // Stop the event from propagating to parent AccordionItem
                      }}
                    >
                      <CheckboxField
                        className="tl-item-checkbox"
                        name={translationKey}
                        id={translationKey}
                        labelText=''
                        onChange={individualCheckboxHandler.bind(null, translationKey)}
                        checked={selectAllKeys || selectedKeys[translationKey]}
                      />
                    </div>
                    <div className="tl-item-body">
                      <div className="tl-item-key">{ translationKey }</div>
                      <Paragraph> { `Translated: ${translated}/${total} ${translated === total ? '✅' : '❌'}` }</Paragraph>
                    </div>

                  </div>
                )}
              >
                <div className='tl-item-button-wrapper'>
                  <Button onClick={() => { sdk?.navigator?.openEntry(entryId, { slideIn: true }); }}>
                    Link to entry
                  </Button>
                  <CopyButton
                    copyValue={translationKey}
                    tooltipCopiedText="The key has been copied"
                    tooltipText="Copy this key to clipboard"
                  />
                </div>
                <Paragraph className=''>
                  { `Field Name: ${fieldName}` }
                </Paragraph>
                <Paragraph className=''>
                  { `Field Type: ${localizedFields[translationKey]}` }
                </Paragraph>
                <TranslationResultTable
                  languages={translationsList?.[translationKey]?.languages}
                  fieldType={`${localizedFields[translationKey]}`}
                />
              </AccordionItem>
            </Accordion>
          )
        })
      }
    </div>
  );
};

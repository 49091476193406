import React from 'react';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@contentful/forma-36-react-components';

import './TranslationResultTable.css';

import {
  FIELD_TYPE_SYMBOL,
  SYMBOL_MAX_CHARACTER_COUNT,
} from '../../utils/constants';

export const TranslationResultTable = ({
  languages,
  fieldType,
} : {
  languages: any,
  fieldType: string
}) => {
  const languagesArray = Object.keys(languages || {});
  const isRichText = fieldType === 'RichText';
  return (
    <>
      <Table className="table-wrapper">
        <TableHead>
          <TableRow>
            <TableCell>Language</TableCell>
            <TableCell>Text</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {
          languagesArray.map((lang, idx) => {
            const shouldShowAsError = fieldType === FIELD_TYPE_SYMBOL &&
              languages[lang] &&
              languages[lang].length > SYMBOL_MAX_CHARACTER_COUNT;

            return (
              <TableRow key={idx} selected={shouldShowAsError}>
                <TableCell className={ shouldShowAsError ? 'error' : '' }>{lang}</TableCell>
                <TableCell className={ shouldShowAsError ? 'error' : '' }>
                  {(languages[lang] && isRichText && JSON.stringify(languages[lang])) || languages[lang] || 'null'}
                </TableCell>
              </TableRow>
            )
          })
        }
        </TableBody>
      </Table>
    </>
  )
};

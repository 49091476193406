import React, { useState, useEffect } from 'react';
import {
  Button,
  CheckboxField,
  Note,
  Paragraph,
  Spinner,
} from '@contentful/forma-36-react-components';
import {
  checkTranslations,
  authorTranslations,
  SuccessTypes,
  FailureTypes,
} from '../../utils';
import './CheckTranslation.css';
import { TranslationList } from '../TranslationList/TranslationList';

const CheckTranslation = ({ sdk }: { sdk: any }) => {
  const [fetchInProgress, setFetchInProgress] = useState(true);
  const [authorInProgress, setAuthorInProgress] = useState(false);
  const [successType, setSuccessType] = useState(SuccessTypes.AllTranslated);
  const [failureType, setFailureType] = useState(FailureTypes.OtherFailure);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showFailureMessage, setShowFailureMessage] = useState(false);
  const [showErrorKeys, setShowErrorKeys] = useState(false);
  const [errorKeys, setErrorKeys] = useState<any>('');
  const [errorInfo, setErrorInfo] = useState('');
  const [autoPublish, setAutoPublish] = useState(false);
  const [translations, setTranslations] = useState<any>({});
  const [localizedFields, setLocalizedFields] = useState({});
  const [currentNamespace, setCurrentNamespace] = useState('');
  // stores which translation keys are selected
  // used for filtering when authoring to Contentful
  const [selectedKeys, setSelectedKeys] = useState<any>({});

  const {
    parameters: {
      installation: {
        deliveryAccessToken = '',
        previewAccessToken = '',
        managementAccessToken = '',
        translationServiceUrl = '',
        translationNamespaceMap = [],
        enableTestingMode = false,
      } = {},
    } = {},
    ids: {
      space = '',
      entry = '',
      environment = '',
    } = {},
    space: sdkSpace,
  } = sdk;


  useEffect(() => {
    if (!fetchInProgress) {
      return;
    }

    checkTranslations(
      translationServiceUrl,
      entry,
      deliveryAccessToken,
      previewAccessToken,
      space,
      environment,
      sdkSpace,
      translationNamespaceMap,
    ).then((result: any) => {
      if (result.type === SuccessTypes.AllTranslated) {
        setSuccessType(SuccessTypes.AllTranslated);
      } else {
        // partially translated
        setSuccessType(SuccessTypes.PartiallyTranslated);
      }

      setErrorInfo('');
      setTranslations(result.translations);
      setLocalizedFields(result.localizedFields?.data);
      setCurrentNamespace(result.namespace);
      setShowSuccessMessage(true)
      setFetchInProgress(false);
    }).catch((err) => {
      if (err.type === FailureTypes.NoAssociatedGroup) {
        setFailureType(FailureTypes.NoAssociatedGroup);
      } else {
        setFailureType(FailureTypes.OtherFailure);
        setErrorInfo(JSON.stringify(err?.error));
      }

      setFetchInProgress(false);
      setShowSuccessMessage(false);
      setShowFailureMessage(true);
    });
  }, [
    fetchInProgress,
    translationServiceUrl,
    entry,
    deliveryAccessToken,
    previewAccessToken,
    space,
    environment,
    sdkSpace,
    translationNamespaceMap,
  ]);

  const handleAuthorTranslation = async () => {
    setAuthorInProgress(true);

    const translationsData = translations?.data?.translations ?? {};

    const filteredTranslationsData = Object.keys(translationsData).reduce((acc, key) => {
      if (selectedKeys[key]) {
        return {
          ...acc,
          [key]: translationsData[key],
        };
      }

      return acc;
    }, {});

    try {
      const response = await authorTranslations({
        translationServiceUrl,
        autoPublish,
        managementAccessToken,
        space,
        translations: filteredTranslationsData,
        testingMode: enableTestingMode,
      });

      // get non-200 status
      const unsuccessfulRequests = response?.data?.filter((res: any) => res.status >= 400);
      if (unsuccessfulRequests?.length) {
        setSuccessType(SuccessTypes.PartiallyAuthored);
        setShowErrorKeys(true);
        setErrorKeys(unsuccessfulRequests.map((res:any)=>{
          return(<Paragraph>
            {`${res.status}`}
            {res.name && ` - ${res.name}: `}
            {res.message && `${res.message} `}
          </Paragraph>)

        }));
      } else {
        setSuccessType(SuccessTypes.SuccessfullyAuthored);
        setShowErrorKeys(false);
      }

      setShowSuccessMessage(true);
      setShowFailureMessage(false);
    } catch (e: any) {
      if (e.status === 504) {
        setFailureType(FailureTypes.TimedOut);
        setShowSuccessMessage(false);
        setShowErrorKeys(false);
        setShowFailureMessage(true);
      }
      else {
      setFailureType(FailureTypes.OtherFailure);
      setShowSuccessMessage(false);
      setShowErrorKeys(false);
      setShowFailureMessage(true);
      }
    }

    setAuthorInProgress(false);
  }

  return (
    <div className="wrapper">
      {
        fetchInProgress && (<Paragraph>Checking <Spinner /></Paragraph>)
      }
      {
        !fetchInProgress && showSuccessMessage && (
          <div className="success-message-wrapper">
            <Note noteType="positive" className="success-message-note">
              { successType }
            </Note>
            {showErrorKeys && (
              <Note title="The following errors were reported" noteType="warning" className="failure-message-note">
              { errorKeys }
              </Note>
            )}
            <Paragraph className="success-message-paragraph">
              The translations are retrieved from "{currentNamespace}" namespace
            </Paragraph>
            <Paragraph className="success-message-paragraph">
              Click "Author translations" to push the translations back into Contentful. You can also choose whether you would like to publish the translations live to customers. Please note that this status is only valid for the first translation of an entry. We can only verify that translations are present, not that they have been updated.
            </Paragraph>
            <CheckboxField
              className="auto-publish-checkbox"
              name="auto-publish"
              id="auto-publish"
              labelText="Auto-publish translation to page"
              checked={autoPublish}
              onChange={() => setAutoPublish(!autoPublish)}
            />
            <Button
              className="author-button"
              buttonType="primary"
              onClick={handleAuthorTranslation}
            >
              { authorInProgress ? <>Authoring<Spinner /></> : 'Author selected translations' }
            </Button>
            <TranslationList
              sdk={sdk}
              translations={translations}
              localizedFields={localizedFields}
              updateSelectedKeys={setSelectedKeys}
            />
          </div>
        )
      }
      {
        !fetchInProgress && showFailureMessage && (
          <>
            <div className="failure-message-wrapper">
              <Note title={failureType} noteType="warning" className="failure-message-note">
                { `${errorInfo}` }
              </Note>
            </div>
            <Button
              className="retry-button"
              buttonType="primary"
              onClick={() => { setFetchInProgress(true); }}
            >
              Retry
            </Button>
          </>
        )
      }
    </div>
  );
}

export default CheckTranslation;
